import React, { useRef, useEffect, useImperativeHandle, forwardRef, useContext } from 'react';
import styles from './Chat.module.css';
import MonacoEditor from 'react-monaco-editor';
import { ThemeContext } from "../../redux/ThemeContext";
import {AppContext} from "../../redux/AppContext";

const PromptEditor = forwardRef(({
                                     isCodeEditor,
                                     value,
                                     onChange,
                                     onControlEnter,
                                     onDidPaste,
                                     chatLayout,
                                     showMinMap = false
                                 }, ref) => {
    const { theme } = useContext(ThemeContext);
    const editorRef = useRef(null);
    const containerRef = useRef(null);
    const sizeRef = useRef({ width: 0, height: 0 });
    const { state } = useContext(AppContext);
    const { resizeDetected } = state;

    useEffect(()=> {
        // console.log( 'resize detected called!');
        handleResize();
    }, [resizeDetected])

    const handleResize = () => {
        // if (editorRef.current) {
        //     console.log( 'layout() called');
        //     editorRef.current.layout();
        // }

        if (containerRef.current) {
            const currentWidth = containerRef.current.offsetWidth;
            const currentHeight = containerRef.current.offsetHeight;
            if (
                currentWidth !== sizeRef.current.width ||
                currentHeight !== sizeRef.current.height
            ) {
                sizeRef.current = { width: currentWidth, height: currentHeight };
                if (editorRef.current) {
                    // console.log( 'layout() called');
                    editorRef.current.layout();
                }
            } else {
                // console.log( 'size not modified');
            }
        }
    };

    return isCodeEditor ? (
        <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
            <MonacoEditor
                width="100%"
                height="100%"
                maxHeight="100%"
                minHeight="0"
                language="plaintext"
                theme={theme === "dark" ? "vs-dark" : "vs"}
                value={value}
                options={{
                    selectOnLineNumbers: true,
                    tabSize: 2,
                    fontSize: 12,
                    wordWrap: 'on',
                    lineNumbers: 'off',
                    folding: false,
                    scrollbar: { horizontal: 'hidden' },
                    glyphMargin: false,
                    contextmenu: false,
                    minimap: { enabled: showMinMap },
                    quickSuggestions: false,
                    codeLens: false,
                }}
                onChange={(newValue, e) => onChange(newValue, e)}
                editorDidMount={(editor, monaco) => {
                    editor.addCommand(
                        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
                        async () => {
                            if (onControlEnter) {
                                onControlEnter();
                            }
                        }
                    );
                    editorRef.current = editor;
                    // Get the editor's DOM node
                    const editorElement = editor.getDomNode();
                    if (editorElement) {
                        editorElement.addEventListener("paste", onDidPaste);
                    }

                    // Cleanup event listener when component unmounts
                    return () => {
                        if (editorElement) {
                            editorElement.removeEventListener("paste", onDidPaste);
                        }
                    };
                }}
            />
         </div>
    ) : (
        <textarea
            style={{ height: "100%", width: "100%", backgroundColor: "transparent", border: "none", padding: "8px" }}
            className={`code`}
            value={value}
            onPaste ={onDidPaste}
            onChange={(e) => onChange(e.target.value, e)}
            onKeyDown={(event) => {
                if (event.ctrlKey && event.key === 'Enter') {
                    if (onControlEnter) {
                        event.preventDefault();
                        onControlEnter();
                    }
                }
            }}
        />
    );
});

export default PromptEditor;
